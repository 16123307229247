import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col } from "reactstrap";
import Loader from '../Loader/Loader'

const options = {
    plugins: {
        legend: { display: false },
        tooltip: { enabled: true },
    },
    scales: {
        y: {
            ticks: {
                padding: 0,
                color: "#9f9f9f",
                beginAtZero: false,
                maxTicksLimit: 10,
            },
            grid: {
                drawBorder: true,
                display: false,
            },
        },
        x: {
            barPercentage: 1.6,
            grid: {
                drawBorder: true,
                display: false,
            },
            ticks: {
                padding: 0,
                color: "#9f9f9f",
                maxTicksLimit: 50
            },
        },
    },
}
class ChartRevenue extends Component {
    constructor(props) {
        super(props);
        this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
        this.state = {
            data: [],
            labels: [],
            stats: [],
            isLoading: true,
        };
    }
    loadStats1() {
        this.setState({ isLoading: true })
        return fetch('https://tdb.confotex.com/api/orders-stats-1/', {
            method: 'GET',
            headers: {
                'authorization': window.sessionStorage.getItem('session_token'),
                "x-my-custom-header": window.sessionStorage.getItem('iduser'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((reponse) => reponse.json())
            .then((data) => {

                if (data.reponse === "true") {

                    let labels = []
                    data.stats.forEach(element => {
                        labels.push(element.abr_prod)
                    });
                    let data2 = []
                    data.stats.forEach(element => {
                        data2.push(element.nb)
                    });

                    this.setState({ labels: labels, data: data2, stats: data.stats, isLoading: false })

                } else if (data.reponse === "false") {
                    this.showNotification(data)
                }
                else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.loadStats1()
    }
    render() {
        return (
            <>
                <Row>
                    <Col md="4">
                        <Card >
                            <CardBody className="justify-content-center d-flex">
                                <table className="table-sm table-hover table-responsive" style={{ height: 400, }}>
                                    <thead>
                                        <th>Produit</th>
                                        <th>Quantité</th>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.stats.map((value, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{value.abr_prod}</td>
                                                        <td>{value.nb}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="8">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h5">Quantité des produits des commandes en cours</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Bar
                                    data={(canvas) => {
                                        return {
                                            labels: this.state.labels,
                                            datasets: [
                                                {
                                                    borderColor: "lightblue",
                                                    backgroundColor: [
                                                        '#D98880',
                                                        '#EC7063',
                                                        '#AF7AC5',
                                                        '#A569BD',
                                                        '#5499C7',
                                                        '#5DADE2',
                                                        '#48C9B0',
                                                        '#45B39D',
                                                        '#52BE80',
                                                        '#58D68D',
                                                        '#F4D03F',
                                                        '#F5B041',
                                                        '#EB984E',
                                                        '#DC7633',
                                                        '#641E16',
                                                        '#512E5F',
                                                    ],
                                                    pointRadius: 0,
                                                    pointHoverRadius: 0,
                                                    borderWidth: 1,
                                                    tension: 0.4,
                                                    fill: true,
                                                    data: this.state.data
                                                }
                                            ],
                                        };
                                    }}
                                    options={options}
                                    width={400}
                                    height={200}
                                />
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats" onClick={() => this.loadStats1()}>
                                    <i className="fas fa-history" /> Actualiser
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>

                </Row>

            </>

        );
    }
}

export default ChartRevenue;
