import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Col, FormGroup, Row } from "reactstrap";
import Loader from '../Loader/Loader'
import Select from 'react-select'

const options = {
    plugins: {
        legend: { display: false },
        tooltip: { enabled: true },
    },
    maintainAspectRatio: false,
    pieceLabel: {
        render: "percentage",
        fontColor: ["white"],
        precision: 2,
    },
    scales: {
        y: {
            ticks: {
                display: false,
                padding: 0,
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
        x: {
            barPercentage: 1.6,
            grid: {
                drawBorder: false,
                display: false,
            },
            ticks: {
                padding: 0,
                display: false,
            },
        },
    },
}

class ChartStats2 extends Component {
    constructor(props) {
        super(props);
        this.year = ""
        this.month = ""
        this.state = {
            isLoading: false,
            data: [],
            labels: [],
            yearsList: [],
            monthsList: [
                { label: "Janvier", value: "01" },
                { label: "Février", value: "02" },
                { label: "Mars", value: "03" },
                { label: "Avril", value: "04" },
                { label: "Mai", value: "05" },
                { label: "Juin", value: "06" },
                { label: "Juillet", value: "07" },
                { label: "Août", value: "08" },
                { label: "Septembre", value: "09" },
                { label: "Octobre", value: "10" },
                { label: "Novembre", value: "11" },
                { label: "Décembre", value: "12" },
            ]
        };
    }
    loadStats2() {
        if (this.year !== "" && this.month !== "") {
            this.setState({ isLoading: true })
            return fetch('https://tdb.confotex.com/api/orders-stats-2', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    session_token: window.sessionStorage.getItem('session_token'),
                    annee: this.year.value,
                    mois: this.month.value,
                })
            })
                .then((reponse) => reponse.json())
                .then((data) => {
                    if (data.reponse === "false") {
                        this.setState({ isLoading: false })
                    } else if (data.reponse === "true") {
                        let labels = []
                        data.stats.forEach(element => {
                            labels.push(element.abr_prod)
                        });
                        let data2 = []
                        data.stats.forEach(element => {
                            data2.push(element.nb)
                        });
                        this.setState({ labels: labels, data: data2, stats: data.stats, isLoading: false })
                    }
                })
                .catch((error) => console.error(error));
        }

    }
    generateYears() {
        let max = new Date().getFullYear()
        let array = []
        for (let i = 2020; i <= max; i++) {
            let obj = {}
            obj.value = i
            obj.label = i
            array.push(obj)
        }
        this.setState({ yearsList: array })
    }
    componentDidMount() {
        this.generateYears()
    }
    render() {
        return (
            <Card>
                {/* DISPLAY LOADER */}
                {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                    <Loader />
                </div>}
                <CardHeader>
                    <CardTitle tag="h5">Produits les plus fabriquées</CardTitle>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <label>
                                    Année
                                </label>
                                <Select value={this.state.year} options={this.state.yearsList} onChange={(event) => { this.year = event; this.loadStats2() }} isSearchable={false} />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <label>
                                    Mois
                                </label>
                                <Select value={this.state.month} options={this.state.monthsList} onChange={(event) => { this.month = event; this.loadStats2() }} isSearchable={false} />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody style={{ height: 250 }}>


                    <Doughnut
                        data={(canvas) => {
                            return {
                                labels: this.state.labels,
                                datasets: [
                                    {
                                        label: "Ventes",
                                        pointRadius: 0,
                                        pointHoverRadius: 0,
                                        backgroundColor: ["#4e73df", "#1cc88a", "#36b9cc", "#DAD2D8", "#143642", "#EC9A29", "#A8201A", "#C94277", "#CADBC0", "#94524A", "#2F0A28", "#DAC4F7", "#DDEDAA", "#F0CF65", "#774936"],
                                        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff"],
                                        borderWidth: 0,
                                        data: this.state.data,
                                    },
                                ],
                            };
                        }}
                        options={options}
                    />
                </CardBody>
                <CardFooter>
                    {/* <div className="legend">
                        <i className="fa fa-circle text-primary" /> Opened{" "}
                        <i className="fa fa-circle text-warning" /> Read{" "}
                        <i className="fa fa-circle text-danger" /> Deleted{" "}
                        <i className="fa fa-circle text-gray" /> Unopened
                    </div> */}
                    <hr />
                    <div className="stats" onClick={() => this.loadStats2()}>
                        <i className="fas fa-history" /> Actualiser
                    </div>
                </CardFooter>
            </Card>
        );
    }
}

export default ChartStats2;
