import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button, Badge, Input } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import Loader from '../../components/Loader/Loader';
import { Link } from 'react-router-dom';

class Orders extends Component {
    constructor(props) {
        super(props);
        this.notifRef = React.createRef()
        this.itemsPerPage = 15
        this.numberOfPages = null
        this.state = {
            isLoading: true,
            ordersList: [],
            ordersListFiltered: [],
            currentPage: 1,
        }
    }
    //Handle next page button
    nextPage() {
        if (this.state.currentPage < this.numberOfPages) {
            this.setState({ currentPage: this.state.currentPage + 1 })
        }
    }
    //Handle previous page button
    previousPage() {
        if (this.state.currentPage > 1) {
            this.setState({ currentPage: this.state.currentPage - 1 })
        }
    }
    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    //LOAD ORDERS
    loadOrders() {
        return fetch('https://tdb.confotex.com/api/orders-state/', {
            method: 'GET',
            headers: {
                'authorization': window.sessionStorage.getItem('session_token'),
                "x-my-custom-header": window.sessionStorage.getItem('iduser'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    this.setState({ ordersList: data.ordersList, ordersListFiltered: data.ordersList, isLoading: false })

                } else if (data.reponse === "false") {
                    this.showNotification(data)
                }
                else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => console.error(error));
    }
    searchFor(event) {
        let ordersListFiltered = this.state.ordersList.filter(row => {
            const keys = Object.keys(row)
            return keys.some(key => {
                if (row[key] !== null) {
                    return row[key].toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
                } else {
                    return false
                }
            })
        });
        this.setState({ ordersListFiltered: ordersListFiltered, currentPage: 1 })
    }
    filterByState(state) {
        if (state === 'all') {
            this.setState({ ordersListFiltered: this.state.ordersList, currentPage: 1 })
        } else {
            let ordersListFiltered = this.state.ordersList.filter(row => {
                const keys = Object.keys(row)
                return keys.some(key => {
                    if (row[key] !== null) {
                        return row[key].toLowerCase().indexOf(state.toLowerCase()) !== -1
                    } else {
                        return false
                    }
                })
            });
            this.setState({ ordersListFiltered: ordersListFiltered, currentPage: 1 })
        }

    }
    //LIST ORDERS IN TABLE
    listOrders(numberOfPages) {
        let startingRow = (this.state.currentPage - 1) * this.itemsPerPage
        let endingRow = this.state.currentPage * this.itemsPerPage

        if (this.state.ordersListFiltered.length === 0) {
            return (
                <>
                    <Input type="text" onChange={(event) => this.searchFor(event)} placeholder="Chercher..." />
                    <br></br>
                    <p>Filtres:</p>
                    <Button onClick={() => this.filterByState('En Attente')} className='btn btn-sm btn-warning'>En Attente</Button>
                    <Button onClick={() => this.filterByState('En Cours')} className='btn btn-sm btn-primary'>En Cours</Button>
                    <Button onClick={() => this.filterByState('Terminée')} className='btn btn-sm btn-success'>Terminées</Button>
                    <Button onClick={() => this.filterByState('all')} className='btn btn-sm btn-secondary'>Toutes les commandes</Button>
                    <br></br>
                    <p>Vous n'avez encore reçu aucune commande</p>
                </>
            )
        } else {
            return (
                <>
                    <Input type="text" onChange={(event) => this.searchFor(event)} placeholder="Chercher.." />
                    <br></br>
                    <p>Filtres:</p>
                    <Button onClick={() => this.filterByState('En Attente')} className='btn btn-sm btn-warning'>En Attente</Button>
                    <Button onClick={() => this.filterByState('En Cours')} className='btn btn-sm btn-primary'>En Cours</Button>
                    <Button onClick={() => this.filterByState('Terminée')} className='btn btn-sm btn-success'>Terminées</Button>
                    <Button onClick={() => this.filterByState('all')} className='btn btn-sm btn-secondary'>Toutes les commandes</Button>
                    <Table>
                        <thead className="text-primary">
                            <tr>
                                <th>N° Commande</th>
                                <th>Client</th>
                                <th>Date</th>
                                <th className="text-right">Montant</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.ordersListFiltered.slice(startingRow, endingRow).map((value, index) => {

                                let color = "";
                                switch (value.etat_commande) {
                                    case "En Attente":
                                        color = "badge-warning"
                                        break;
                                    case "En Cours":
                                        color = "badge-primary"
                                        break;
                                    case "Terminée":
                                        color = "badge-success"
                                        break;
                                    default:
                                        break;
                                }
                                const formatter = new Intl.NumberFormat('fr-FR', {
                                    style: 'currency',
                                    currency: 'DZD',
                                    minimumFractionDigits: 2
                                });
                                //format amount
                                let total_commande = formatter.format(value.total_commande);
                                //calculate percent
                                let percent = value.qt_finie === 0 ? 0 : Math.round((value.qt_finie * 100) / value.qt_commandee)
                                let color_percent = ""
                                if (percent <= 33) {
                                    color_percent = "badge-danger"
                                } else if (percent > 33 && percent <= 66) {
                                    color_percent = "badge-warning"
                                } else if (percent > 66 && percent <= 99) {
                                    color_percent = "badge-info"
                                }
                                else if (percent === 100) {
                                    color_percent = "badge-success"
                                }

                                return (
                                    <tr key={index}>
                                        <td>{value.num_commande}</td>
                                        <td>{value.ste}</td>
                                        <td>{value.date_heure_h}</td>
                                        <td className="text-right">{total_commande}</td>
                                        <td className="text-center"><Badge style={{ fontSize: 13, fontWeight: 'normal' }} className={color}>{value.etat_commande}</Badge></td>
                                        <td className="text-center"><Badge style={{ fontSize: 13, fontWeight: 'normal' }} className={color_percent}>{percent}%</Badge></td>
                                        <td className="text-right">
                                            <Link to={
                                                {
                                                    pathname: "/orders/details/" + value.num_commande,
                                                    state: {
                                                        num_commande: value.num_commande,
                                                        prevPage: 'orders'
                                                    }
                                                }
                                            }>
                                                <Button className="btn-sm btn-round btn-icon btn-primary mr-2">
                                                    <i className="nc-icon nc-zoom-split"></i>
                                                </Button>
                                            </Link>
                                            {/* <Button className="btn-sm btn-round btn-icon btn-outline-danger mr-2" onClick={() => this.removeOrder(value.num_commande)}>
                                                <i className="nc-icon nc-simple-remove"></i>
                                            </Button> */}
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </Table>
                    <nav>
                        <ul className='pagination'>
                            <li className='page-item'><button className='page-link btn btn-sm btn-primary' onClick={() => this.previousPage()}>Previous</button></li>
                            <li className='page-item'><button className='page-link btn btn-sm btn-primary'>{this.state.currentPage + " / " + numberOfPages}</button></li>
                            <li className='page-item'><button className='page-link btn btn-sm btn-primary' onClick={() => this.nextPage()}>Next</button></li>
                        </ul>
                    </nav>
                </>
            )
        }
    }

    componentDidMount() {
        this.loadOrders()
    }
    render() {
        let numberOfPages = Math.ceil(this.state.ordersList.length / this.itemsPerPage)
        this.numberOfPages = numberOfPages
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Row>
                    <Col md="12">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h4">Liste des Commandes</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {this.listOrders(numberOfPages)}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Orders;
