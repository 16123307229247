import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col, FormGroup } from "reactstrap";
import Select from 'react-select'
import Loader from '../Loader/Loader'

const options = {
    plugins: {
        legend: { display: false },
        tooltip: { enabled: true },
    },
    scales: {
        y: {
            ticks: {
                padding: 0,
                color: "#9f9f9f",
                beginAtZero: false,
                maxTicksLimit: 10,
            },
            grid: {
                drawBorder: true,
                display: false,
            },
        },
        x: {
            barPercentage: 1.6,
            grid: {
                drawBorder: true,
                display: false,
            },
            ticks: {
                padding: 0,
                color: "#9f9f9f",
                maxTicksLimit: 50
            },
        },
    },
}
class ChartRevenue extends Component {
    constructor(props) {
        super(props);
        this.year = ""
        this.month = ""
        this.state = {
            dataCq: [],
            dataAte: [],
            labels: [],
            statsCq: [],
            statsAte: [],
            isLoading: false,
            yearsList: [],
            monthsList: [
                { label: "Janvier", value: "01" },
                { label: "Février", value: "02" },
                { label: "Mars", value: "03" },
                { label: "Avril", value: "04" },
                { label: "Mai", value: "05" },
                { label: "Juin", value: "06" },
                { label: "Juillet", value: "07" },
                { label: "Août", value: "08" },
                { label: "Septembre", value: "09" },
                { label: "Octobre", value: "10" },
                { label: "Novembre", value: "11" },
                { label: "Décembre", value: "12" },
            ]
        };
    }
    loadStats3() {
        if (this.year !== "" && this.month !== "") {
            this.setState({ isLoading: true })
            return fetch('https://tdb.confotex.com/api/orders-stats-3/', {
                method: 'POST',
                headers: {
                    'authorization': window.sessionStorage.getItem('session_token'),
                    "x-my-custom-header": window.sessionStorage.getItem('iduser'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    year: this.year.value,
                    month: this.month.value,
                })
            })
                .then((reponse) => reponse.json())
                .then((data) => {
                    if (data.reponse === "true") {

                        let labels = []
                        data.stats.forEach(element => {
                            labels.push(element.abr_prod)
                        });
                        let data2 = []
                        data.stats.forEach(element => {
                            data2.push(element.somme)
                        });

                        this.setState({ labels: labels, dataCq: data2, statsCq: data.stats, isLoading: false })

                    } else if (data.reponse === "false") {
                        this.showNotification(data)
                    }
                    else {
                        this.setState({ isLoading: false })
                    }
                })
                .catch((error) => console.error(error));
        }
    }
    loadStats4() {
        if (this.year !== "" && this.month !== "") {
            this.setState({ isLoading: true })
            return fetch('https://tdb.confotex.com/api/orders-stats-4/', {
                method: 'POST',
                headers: {
                    'authorization': window.sessionStorage.getItem('session_token'),
                    "x-my-custom-header": window.sessionStorage.getItem('iduser'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    year: this.year.value,
                    month: this.month.value,
                })
            })
                .then((reponse) => reponse.json())
                .then((data) => {
                    if (data.reponse === "true") {

                        let labels = []
                        data.stats.forEach(element => {
                            labels.push(element.abr_prod)
                        });
                        let data2 = []
                        data.stats.forEach(element => {
                            data2.push(element.somme)
                        });

                        this.setState({ labels: labels, dataAte: data2, statsAte: data.stats, isLoading: false })

                    } else if (data.reponse === "false") {
                        this.showNotification(data)
                    }
                    else {
                        this.setState({ isLoading: false })
                    }
                })
                .catch((error) => console.error(error));
        }

    }
    generateYears() {
        let max = new Date().getFullYear()
        let array = []
        for (let i = 2020; i <= max; i++) {
            let obj = {}
            obj.value = i
            obj.label = i
            array.push(obj)
        }
        this.setState({ yearsList: array })
    }
    componentDidMount() {
        this.generateYears()

    }
    render() {
        let array = []
        if (this.state.statsCq.length > 0 && this.state.statsAte.length > 0) {

            for (let i = 0; i < this.state.statsAte.length; i++) {
                let obj = {}
                obj.abr_prod = this.state.statsAte[i].abr_prod
                obj.somme_ate = this.state.statsAte[i].somme
                obj.somme_cq = this.state.statsCq[i].somme
                array.push(obj)
            }
        }


        return (
            <>
                <Row>
                    <Col md="4">
                        <Card >
                            <CardBody className="justify-content-center d-flex">
                                <table className="table-sm table-hover table-responsive" style={{ height: 400, }}>
                                    <thead>
                                        <th>Produit</th>
                                        <th>Qte Fabriquée</th>
                                        <th>Qte Contrôlée</th>
                                    </thead>
                                    <tbody>
                                        {
                                            array.length > 0 &&
                                            array.map((value, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{value.abr_prod}</td>
                                                        <td style={{ color: 'blue' }}>{value.somme_ate}</td>
                                                        <td style={{ color: 'green' }}>{value.somme_cq}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="8">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h5">Quantité de produits fabriqués / contrôlés par produit par mois</CardTitle>
                                <CardHeader>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>
                                                    Année
                                                </label>
                                                <Select value={this.state.year} options={this.state.yearsList} onChange={(event) => { this.year = event; this.loadStats3(); this.loadStats4() }} isSearchable={false} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>
                                                    Mois
                                                </label>
                                                <Select value={this.state.month} options={this.state.monthsList} onChange={(event) => { this.month = event; this.loadStats3(); this.loadStats4() }} isSearchable={false} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </CardHeader>
                            <CardBody>
                                <Bar
                                    data={(canvas) => {
                                        return {
                                            labels: this.state.labels,
                                            datasets: [
                                                {
                                                    borderColor: "blue",
                                                    backgroundColor:
                                                        'blue'
                                                    ,
                                                    pointRadius: 0,
                                                    pointHoverRadius: 0,
                                                    borderWidth: 1,
                                                    tension: 0.4,
                                                    fill: true,
                                                    data: this.state.dataCq
                                                },
                                                {
                                                    borderColor: "green",
                                                    backgroundColor: 'green',
                                                    pointRadius: 0,
                                                    pointHoverRadius: 0,
                                                    borderWidth: 1,
                                                    tension: 0.4,
                                                    fill: true,
                                                    data: this.state.dataAte
                                                }
                                            ],
                                        };
                                    }}
                                    options={options}
                                    width={400}
                                    height={100}
                                />
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats" onClick={() => { this.loadStats3(); this.loadStats4() }}>
                                    <i className="fas fa-history" /> Actualiser
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>

                </Row>

            </>

        );
    }
}

export default ChartRevenue;
