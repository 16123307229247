import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button } from "reactstrap";
import Loader from '../../components/Loader/Loader';
import { Link } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";

class Products extends Component {
    constructor(props) {
        super(props)
        this.notifRef = React.createRef()
        this.state = {
            isLoading: true,
            productsList: [],
        }
    }
    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    loadProducts() {
        return fetch('https://tdb.confotex.com/api/products/', {
            method: 'GET',
            headers: {
                'authorization': window.sessionStorage.getItem('session_token'),
                "x-my-custom-header": window.sessionStorage.getItem('iduser'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.reponse === "true") {
                    this.setState({ productsList: data.productsList, isLoading: false })

                } else if (data.reponse === "false") {
                    this.showNotification(data)
                }
                else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((error) => console.error(error));
    }
    //LIST PRODUCTS IN TABLE
    listProducts() {
        if (this.state.productsList.length === 0) {
            return (
                <p>Aucun produit trouvé</p>
            )
        } else {
            return (
                <Table>
                    <thead className="text-primary">
                        <tr>
                            <th></th>
                            <th>Code</th>
                            <th>Désignation</th>
                            <th>Catégorie</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.productsList.map((value, index) => {
                            return (
                                <tr key={index}>
                                    <td><img style={{ height: 50, }} src={value.picture_path} alt={value.idpic}></img></td>
                                    <td>{value.id_prod}</td>
                                    <td>{value.nom_prod}</td>
                                    <td>{value.lib_cat}</td>
                                    <td className="text-right">
                                        <Link to={
                                            {
                                                pathname: "/products/product-form/" + value.id_prod,
                                                id_prod: value.id_prod
                                            }
                                        }>
                                            <Button className="btn-sm btn-round btn-icon btn-primary mr-2">
                                                <i className="nc-icon nc-zoom-split"></i>
                                            </Button>
                                        </Link>
                                        <Button className="btn-sm btn-round btn-icon btn-outline-danger mr-2" onClick={() => this.removeProduct(value.id_prod)}>
                                            <i className="nc-icon nc-simple-remove"></i>
                                        </Button>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </Table>
            )
        }
    }
    //REMOVE PRODUCT BY ID
    removeProduct(id_prod) {
        return fetch('https://tdb.confotex.com/api/products/', {
            method: 'DELETE',
            headers: {
                'authorization': window.sessionStorage.getItem('session_token'),
                "x-my-custom-header": window.sessionStorage.getItem('iduser'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                id_prod: id_prod
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.loadProducts()
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.loadProducts()
    }

    render() {
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Row>
                    <Col md="6">
                        <Link to={
                            { pathname: "/products/product-form/add" }
                        }><Button className="btn-round" color="primary">Ajouter un Produit</Button></Link>
                    </Col>
                    <Col md="12">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h4">Liste des Produits</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {this.listProducts()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Products;
