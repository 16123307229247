import Dashboard from "views/Dashboard.js";
import ProdForm from "views/Products/ProdForm";
import Products from "views/Products/Products";
import Orders from "views/Orders/Orders";
import OrdersDetails from "views/Orders/OrdersDetails";

var routes = [
  {
    path: "/dashboard",
    name: "Tableau de bord",
    name_ar: "لوحة القيادة",
    icon: "nc-icon nc-layout-11",
    component: Dashboard,
  },
  {
    path: "/products",
    name: "Produits",
    name_ar: "عملائي",
    icon: "nc-icon nc-tag-content",
    component: Products,
  },
  {
    path: "/products/product-form/:id_prod",
    name: "Fiche Produit",
    icon: "nc-icon nc-tag-content",
    component: ProdForm,
    display: false
  },
  {
    path: "/orders",
    name: "Commandes",
    icon: "nc-icon nc-cart-simple",
    component: Orders,
  },
  {
    path: "/orders/details/:num_commande",
    name: "Commandes",
    icon: "nc-icon nc-cart-simple",
    component: OrdersDetails,
    display: false
  },
];
export default routes;
