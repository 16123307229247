import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Input, FormGroup, CustomInput, Label } from "reactstrap";

import Select from 'react-select'
import ReactQuill from 'react-quill';
import '../../../node_modules/react-quill/dist/quill.snow.css';

class ProdInfos extends Component {
    constructor(props) {
        super(props)
        this.form = {}
        this.showNotification = this.props.showNotification
        this.setIdProd = this.props.setIdProd
        this.state = {
            disabled: false,
            catList: [
                { id: "id_cat", value: "CAT1", label: "Collier cervical" },
                { id: "id_cat", value: "CAT2", label: "Coude/Bras/Épaule" },
                { id: "id_cat", value: "CAT3", label: "Main/Poignet" },
                { id: "id_cat", value: "CAT4", label: "Région abdominale" },
                { id: "id_cat", value: "CAT5", label: "Région lombaire" },
                { id: "id_cat", value: "CAT6", label: "Cuisse/Genou" },
                { id: "id_cat", value: "CAT6", label: "Cheville" },
            ],
            isLoading: true,
            product: [],
            nouveauState: false,
            masquerState: false,
            id_prod: this.props.id_prod,
            desc_prod: ""
        }
    }
    //SET FORM DATA
    setFormData = (event) => {
        if (event.value !== undefined) {
            this.form[event.id] = event.value
            if (event.value === "") {
                delete this.form[event.id]
            }
        } else {
            this.form[event.target.id] = event.target.value
            if (event.target.value === "") {
                delete this.form[event.target.id]
            }
        }
    }
    //SET PRODUCT DESCRIPTION
    setDescProd(text) {
        this.form['desc_prod'] = text
    }
    //HANDLE SWITCH OPTIONS
    handleSwitch(event) {
        if (event.target.id === "masquer") {
            this.setState({ masquerState: !this.state.masquerState })
        } else if (event.target.id === "new") {
            this.setState({ nouveauState: !this.state.nouveauState })
        }
    }

    //SAVE PRODUCT TO DATABASE
    saveProd() {
        console.log(this.form);
        this.state.nouveauState === true ? this.form['news'] = 1 : this.form['news'] = 0
        //this.state.masquerState === true ? this.form['masquer'] = 1 : this.form['masquer'] = 0

        return fetch('https://tdb.confotex.com/api/products/', {
            method: 'POST',
            headers: {
                'authorization': window.sessionStorage.getItem('session_token'),
                "x-my-custom-header": window.sessionStorage.getItem('iduser'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                form: this.form
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                if (data.repose === "true") {
                    this.showNotification(data)
                    if (data.id_prod !== undefined) {
                        this.setIdProd(data.id_prod)
                    }
                } else {
                    this.showNotification(data)
                }

            })
            .catch((error) => console.error(error));
    }
    //UPDATE PRODUCT DATA
    updateProd() {
        this.state.nouveauState === true ? this.form['new'] = 1 : this.form['new'] = 0
        //this.state.masquerState === true ? this.form['masquer'] = 1 : this.form['masquer'] = 0
        return fetch('https://tdb.confotex.com/api/products/', {
            method: 'UPDATE',
            headers: {
                'authorization': window.sessionStorage.getItem('session_token'),
                "x-my-custom-header": window.sessionStorage.getItem('iduser'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                form: this.form,
                id_prod: this.state.id_prod
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
            })
            .catch((error) => console.error(error));
    }
    //GET PRODUCT DATA
    loadProductById() {
        if (this.state.id_prod !== "add") {
            return fetch('https://tdb.confotex.com/api/products/' + this.state.id_prod, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
                .then((reponse) => reponse.json())
                .then((data) => {
                    let product = data.productData
                    const defaultValue = this.state.catList.find(opt => opt.value === product.id_cat);
                    this.setState({ product: product, id_cat_select: defaultValue, desc_prod: product.desc_prod })

                    this.form.nom_prod = product.nom_prod
                    this.form.id_cat = product.id_cat
                    this.form.abr_prod = product.abr_prod
                    this.form.new = product.new
                    this.form.desc_prod = product.desc_prod
                    this.form.taille = product.taille
                    this.form.prix_prod = product.prix_prod

                    //let masquer = product.masquer === "1" ? true : false
                    let nouveau = product.new === "1" ? true : false

                    //this.setState({ masquerState: masquer, nouveauState: nouveau })
                    this.setState({ nouveauState: nouveau })
                })
                .catch((error) => console.error(error));
        }
    }
    componentDidMount() {
        this.loadProductById()
    }
    render() {
        let toolbarOptions = [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],

            ['clean']                                         // remove formatting button
        ];
        return (
            <Card>
                <CardHeader>
                    <CardTitle tag="h5">Information sur le Produit</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row className="justify-content-center">
                        <Col md="12">
                            <FormGroup>
                                <Label for="exampleCheckbox">Options</Label>
                                <div>
                                    {/* <CustomInput type="switch" id="masquer" name="masquer" label="Hide" inline onChange={(event) => this.handleSwitch(event)} checked={this.state.masquerState} /> */}
                                    <CustomInput type="switch" id="new" name="new" label="Nouveau" inline onChange={(event) => this.handleSwitch(event)} checked={this.state.nouveauState} />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="5">
                            <FormGroup>
                                <label htmlFor="nom_prod">
                                    Désignation
                                </label>
                                <Input defaultValue={this.state.product.nom_prod} type="text" id="nom_prod" onChange={(event) => this.setFormData(event)} />
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <label htmlFor="abr_prod">
                                    Abréviation
                                </label>
                                <Input defaultValue={this.state.product.abr_prod} type="text" id="abr_prod" onChange={(event) => this.setFormData(event)} />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <label htmlFor="taille">
                                    Tailles disponibles
                                </label>
                                <Input defaultValue={this.state.product.taille} type="text" id="taille" onChange={(event) => this.setFormData(event)} />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <label htmlFor="prix_prod">
                                    Prix (DA)
                                </label>
                                <Input defaultValue={this.state.product.prix_prod} type="text" id="prix_prod" onChange={(event) => this.setFormData(event)} />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <label htmlFor="id_cat">
                                    Région du Corps
                                </label>
                                <Select value={this.state.id_cat_select} options={this.state.catList} onChange={(event) => { this.setState({ id_cat_select: event }); this.setFormData(event) }} isSearchable={false} />
                            </FormGroup>
                        </Col>
                        <Col md="12" className='mb-4'  >
                            <ReactQuill
                                modules={
                                    { toolbar: toolbarOptions }
                                }
                                theme="snow"
                                value={this.state.desc_prod}
                                onChange={(text) => this.setDescProd(text)}
                                style={{
                                    height: 250,
                                    marginBottom: 50
                                }}
                            />
                        </Col>
                        <Col md="12" className='mt-4'>
                            {
                                this.state.id_prod !== "add" &&
                                <Button disabled={this.state.disabled} onClick={() => this.updateProd()} style={{ width: "100%" }} className="btn-round" color="primary">Mettre à jour</Button>
                            }
                            {
                                this.state.id_prod === "add" &&
                                <Button disabled={this.state.disabled} onClick={() => this.saveProd()} style={{ width: "100%" }} className="btn-round" color="primary">Enregistrer</Button>
                            }

                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

export default ProdInfos;
