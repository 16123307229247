import React from "react";
import { Row, Col, Button } from "reactstrap";
// import ProductsCount from "components/Widgets/ProductsCount";
import OrdersWaitWid from "components/Widgets/OrdersWaitWid";
import OrdersPreparingWid from "components/Widgets/OrdersPreparingWid";
import OrdersFinishedWid from "components/Widgets/OrdersFinishedWid";
import ChartStats1 from "components/Widgets/ChartStats1";
import ChartStats2 from "components/Widgets/ChartStats2";
import ChartStats3 from "components/Widgets/ChartStats3";
import ChartStats5 from "components/Widgets/ChartStats5";
import ChartStats6 from "components/Widgets/ChartStats6";
import ChartStats7 from "components/Widgets/ChartStats7";
// import ChartSellsCount from "components/Widgets/ChartSellsCount";
// import ChartBestSellers from "components/Widgets/ChartBestSellers";
import NotificationAlert from "react-notification-alert";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.notifRef = React.createRef()
    this.lang = window.sessionStorage.getItem('lang').toLocaleLowerCase()
    this.notifRef = React.createRef()
    this.state = {
      offerData: [],
    };
  }
  showNotification = (data) => {
    var options = {};
    options = {
      place: data['place'],
      message: (
        <div>{data['message']}</div>

      ),
      type: data['type'],
      icon: data['icon'],
      autoDismiss: data['autoDismiss'],
    };
    this.notifRef.current.notificationAlert(options)
  }

  componentDidMount() {

  }
  render() {
    return (

      <div className="content">
        <NotificationAlert ref={this.notifRef} />
        <Row>
          <Col lg="4" md="6" sm="6">
            <OrdersWaitWid />
          </Col>
          <Col lg="4" md="6" sm="6">
            <OrdersPreparingWid />
          </Col>
          <Col lg="4" md="6" sm="6">
            <OrdersFinishedWid />
          </Col>
        </Row>

        <Row>
          <Col md="8">
            <ChartStats1 />
          </Col>
          <Col md="4">
            <ChartStats2 />
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <ChartStats3 />
          </Col>
          <Col md="12">
            <ChartStats5 />
          </Col>
          <Col md="12">
            <ChartStats6 />
          </Col>          
          <Col md="12">
            <ChartStats7 />
          </Col>
        </Row>
      </div>

    );
  }
}

export default Dashboard;
