import React, { Component } from 'react';
import { Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import ProdInfos from './ProdInfos';
import ProdPics from './ProdPics';
import '../../assets/css/myshop.css';

class ProdForm extends Component {
    constructor(props) {
        super(props)
        this.notifRef = React.createRef()
        this.state = {
            id_prod: this.props.match.params.id_prod
        }
    }
    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    //SET ID PRODUCT
    setIdProd = (id_prod) => {
        this.setState({ id_prod: id_prod })
    }
    render() {
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Row>

                    {/* DISAPLY PRODUCT INFOS */}
                    <Col md="1" className="d-flex justify-content-center align-items-center">
                        <div className="steps one-half">1/2</div>
                    </Col>
                    <Col md="11" className="mb-4">
                        <Row>
                            <Col md="12">
                                <Link to={
                                    { pathname: "/products" }
                                }><Button className="btn-round float-left" color="primary"><i className='nc-icon nc-minimal-left'></i> Retour</Button>
                                </Link>
                            </Col>
                        </Row>
                        <ProdInfos showNotification={this.showNotification} setIdProd={this.setIdProd} id_prod={this.state.id_prod} />
                    </Col>
                    {/* DISPLAY PICTURES PRODUCT */}
                    {this.state.id_prod !== "add" &&
                        <Col md="1" className="d-flex justify-content-center align-items-center">
                            <div className="steps complete">2/2</div>
                        </Col>}

                    {this.state.id_prod !== "add" &&
                        <Col md="11" className="mb-4">
                            <ProdPics showNotification={this.showNotification} id_prod={this.state.id_prod} />
                        </Col>
                    }
                </Row>
            </div>
        );
    }
}

export default ProdForm;
