import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button, Badge, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import Loader from '../../components/Loader/Loader';

import { Link } from 'react-router-dom';

class OrdersDetails extends Component {
    constructor(props) {
        super(props);
        this.notifRef = React.createRef()
        this.num_commande = this.props.match.params.num_commande
        this.state = {
            isOpen: false,
            isLoading: true,
            orderData: [],
            orderDispatch: [],
            atelierData: [],
            cqData: [],
            livraisonData: [],

        }
    }
    //SHOW NOTIFICATION FUNCTION
    showNotification = (data) => {
        var options = {};
        options = {
            place: data['place'],
            message: (
                <div>{data['message']}</div>

            ),
            type: data['type'],
            icon: data['icon'],
            autoDismiss: data['autoDismiss'],
        };
        this.notifRef.current.notificationAlert(options)
    }
    //LOAD ORDERS
    getOrderDetail = (num_commande) => {
        return fetch('https://tdb.confotex.com/api/orders/' + num_commande, {
            method: 'GET',
            headers: {
                'authorization': window.sessionStorage.getItem('session_token'),
                "x-my-custom-header": window.sessionStorage.getItem('iduser'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                console.log(data);
                if (data.reponse === "true") {
                    this.setState({ orderDispatch: [] })
                    this.setState({ orderData: data.orderData, ste: data.orderData[0].ste, etat_commande: data.orderData[0].etat_commande, date: data.orderData[0].date_heure, isLoading: false })

                } else {
                    this.showNotification(data)
                }
            })
            .catch((error) => console.error(error));
    }

    //
    manufacturingDetails(id_suivi) {
        return fetch('https://tdb.confotex.com/api/manufacturing-details/', {
            method: 'POST',
            headers: {
                'authorization': window.sessionStorage.getItem('session_token'),
                "x-my-custom-header": window.sessionStorage.getItem('iduser'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id_suivi: id_suivi
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                console.log(data);
                if (data.reponse === "true") {
                    this.setState({
                        isOpen: true,
                        atelierData: data.details.atelier,
                        cqData: data.details.cq,
                        livraisonData: data.details.livraison,
                    })

                } else {
                    this.showNotification(data)
                }
            })
            .catch((error) => console.error(error));
    }
    //LIST ORDERS IN TABLE
    listDispatch() {
        if (this.state.orderData.length === 0) {
            return (
                <p>Aucune donnée</p>
            )
        } else {
            return (
                <Table>
                    <thead className="text-primary">
                        <tr>
                            <th>Produit</th>
                            <th>Taille</th>
                            <th className="text-right">Quantité</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.orderData.map((value, index) => {
                            //calculate percent
                            let percent = value.qt_finie === 0 ? 0 : Math.round((value.qt_finie * 100) / value.quantite)
                            let color_percent = ""
                            if (percent <= 33) {
                                color_percent = "badge-danger"
                            } else if (percent > 33 && percent <= 66) {
                                color_percent = "badge-warning"
                            } else if (percent > 66 && percent <= 99) {
                                color_percent = "badge-info"
                            }
                            else if (percent === 100) {
                                color_percent = "badge-success"
                            }
                            return (
                                <tr key={index} onClick={() => this.manufacturingDetails(value.id_suivi)}>
                                    <td>{value.abr_prod}</td>
                                    <td>{value.taille_prod}</td>
                                    <td className="text-right">{value.quantite}</td>
                                    <td className="text-right"><Badge style={{ fontSize: 13, fontWeight: 'normal' }} className={color_percent}>{percent}%</Badge></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            )
        }
    }
    //ACCEPT ORDER
    acceptOrder(num_commande) {
        return fetch('https://tdb.confotex.com/api/orders-accept/', {
            method: 'POST',
            headers: {
                'authorization': window.sessionStorage.getItem('session_token'),
                "x-my-custom-header": window.sessionStorage.getItem('iduser'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                num_commande: num_commande,
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.getOrderDetail(num_commande)
            })
            .catch((error) => console.error(error));
    }
    // ENVOYER LA COMMANDE
    finishOrder(num_commande) {
        return fetch('https://tdb.confotex.com/api/orders-finished/', {
            method: 'POST',
            headers: {
                'authorization': window.sessionStorage.getItem('session_token'),
                "x-my-custom-header": window.sessionStorage.getItem('iduser'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                session_token: window.sessionStorage.getItem('session_token'),
                num_commande: num_commande,
            })
        })
            .then((reponse) => reponse.json())
            .then((data) => {
                this.showNotification(data)
                this.getOrderDetail(num_commande)
            })
            .catch((error) => console.error(error));
    }
    componentDidMount() {
        this.getOrderDetail(this.num_commande)
    }
    render() {
        let color = "";
        switch (this.state.etat_commande) {
            case "En Attente":
                color = "badge-warning"
                break;
            case "En Cours":
                color = "badge-primary"
                break;
            case "Terminée":
                color = "badge-success"
                break;
            default:
                break;
        }
        return (
            <div className="content">
                <NotificationAlert ref={this.notifRef} />
                <Modal
                    centered
                    fullscreen="md"
                    size="md"
                    isOpen={this.state.isOpen}
                    toggle={function noRefCheck() { }}
                >
                    <ModalHeader>
                        {this.state.atelierData.abr_prod} / {this.state.atelierData.taille_prod}
                    </ModalHeader>
                    <ModalBody>
                        <p className='h5'>Atelier</p>
                        <Row className='d-flex justify-content-center'>
                            <Col md="3" className='d-flex flex-column align-items-center'>
                                <Badge style={{ fontSize: 22, width: 50, height: 50, textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }} className="badge-primary">{this.state.atelierData.qt_a_fabriquer}</Badge>
                                En attente
                            </Col>
                            <Col md="3" className='d-flex flex-column align-items-center'>
                                <Badge style={{ fontSize: 22, width: 50, height: 50, textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }} className="badge-warning">{this.state.atelierData.qt_fabrication}</Badge>
                                En cours
                            </Col>
                            <Col md="3" className='d-flex flex-column align-items-center'>
                                <Badge style={{ fontSize: 22, width: 50, height: 50, textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }} className="badge-success">{this.state.atelierData.qt_fabriquee}</Badge>
                                Terminé
                            </Col>
                            <Col md="3" className='d-flex flex-column align-items-center'>
                                <Badge style={{ fontSize: 22, width: 50, height: 50, textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }} className="badge-danger">{this.state.atelierData.qt_retour}</Badge>
                                Retour
                            </Col>
                        </Row>
                        <br></br>
                        <p className='h5'>Contrôle Qualité</p>
                        <Row className='d-flex justify-content-center'>
                            <Col md="3" className='d-flex flex-column align-items-center'>
                                <Badge style={{ fontSize: 22, width: 50, height: 50, textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }} className="badge-primary">{this.state.cqData.qt_a_controler}</Badge>
                                En attente
                            </Col>
                            <Col md="3" className='d-flex flex-column align-items-center'>
                                <Badge style={{ fontSize: 22, width: 50, height: 50, textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }} className="badge-warning">{this.state.cqData.qt_en_controle}</Badge>
                                En cours
                            </Col>
                            <Col md="3" className='d-flex flex-column align-items-center'>
                                <Badge style={{ fontSize: 22, width: 50, height: 50, textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }} className="badge-success">{this.state.cqData.qt_passed}</Badge>
                                Terminé
                            </Col>
                            <Col md="3" className='d-flex flex-column align-items-center'>
                                <Badge style={{ fontSize: 22, width: 50, height: 50, textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }} className="badge-danger">{this.state.cqData.qt_not_passed}</Badge>
                                Echec
                            </Col>
                        </Row>

                        {/* <p className='h5'>Livraison</p>
                        <Row className='d-flex justify-content-center'>
                            <Col md="3" className='d-flex flex-column align-items-center'>
                                <Badge style={{ fontSize: 22, width: 50, height: 50, textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }} className="badge-primary">{this.state.atelierData.qt_a_livrer}</Badge>
                                En attente
                            </Col>
                            <Col md="3" className='d-flex flex-column align-items-center'>
                                <Badge style={{ fontSize: 22, width: 50, height: 50, textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }} className="badge-warning">{this.state.atelierData.qt_en_livraison}</Badge>
                                En cours
                            </Col>
                            <Col md="3" className='d-flex flex-column align-items-center'>
                                <Badge style={{ fontSize: 22, width: 50, height: 50, textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }} className="badge-success">{this.state.atelierData.qt_livree}</Badge>
                                Livré
                            </Col>
                        </Row> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => this.setState({ isOpen: false })}>
                            Fermer
                        </Button>
                    </ModalFooter>
                </Modal>
                <Row className='d-flex justify-content-between'>
                    <Col md="12">
                        <Link to={
                            { pathname: "/orders" }
                        }><Button className="btn-round float-left" color="primary"><i className='nc-icon nc-minimal-left'></i> Retour</Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h4">Informations sur la commande<br></br>
                                    <Badge className={color}>{this.state.etat_commande}</Badge>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="12" className="d-flex">
                                        <Col className="text-left">
                                            <p><strong>N° Commande</strong></p>
                                            <p><strong>Date</strong></p>
                                            <p><strong>Client</strong></p>
                                        </Col>
                                        <Col className="text-right">
                                            <p>{this.num_commande}</p>
                                            <p>{this.state.date}</p>
                                            <p>{this.state.ste}</p>
                                        </Col>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="12">
                        <a target="_blank" rel='noreferrer' href={"https://tdb.confotex.com/api/orders/orders.print.php?num_commande=" + this.num_commande} className='btn btn-round btn-primary'>Imprimer</a>
                    </Col>
                    {/* CONTENU COMMANDE */}
                    <Col md="12">
                        <Card>
                            {/* DISPLAY LOADER */}
                            {this.state.isLoading && <div style={{ backgroundColor: "white", position: 'absolute', height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 99, borderRadius: 15 }}>
                                <Loader />
                            </div>}
                            <CardHeader>
                                <CardTitle tag="h4">Articles</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {this.listDispatch()}

                                {this.state.etat_commande === "En Attente" ? <Button className="btn-success btn-round float-right" onClick={() => this.acceptOrder(this.num_commande)}>Accepter</Button> : ''}

                                {/* ON PREPARING ORDER */}
                                {this.state.etat_commande === "En Cours" && this.state.orderData.type_livraison !== "1" ? <Button className="btn-success btn-round float-right" onClick={() => this.finishOrder(this.num_commande)}>Terminée</Button> : ''}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default OrdersDetails;
