import React, { useContext } from "react";
// javascript plugin used to create scrollbars on windows
//import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";


import { LoginContext } from "utils/context";
import Login from '../views/Login';
//var ps;


function Dashboard(props) {
  const { setUserData } = useContext(LoginContext)
  let loggedIn = window.sessionStorage.getItem('loggedIn')
  const [backgroundColor] = React.useState("confotex");
  const [activeColor] = React.useState("white");
  const mainPanel = React.useRef();
  const location = useLocation();
  const lang = window.sessionStorage.getItem('lang')
  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);
  if (loggedIn === null) {
    return (
      <div className="wrapper" ref={mainPanel}>
        <Switch>
          <Route path="/login">
            <Login setUserData={setUserData} {...props} />
          </Route>
          <Redirect to="/login" />
        </Switch>
      </div>
    )
  } else {

    return (
      <div className="wrapper">
        <Sidebar
          {...props}
          routes={routes}
          bgColor={backgroundColor}
          activeColor={activeColor}
          lang={lang}
        />
        <div className="main-panel" ref={mainPanel}>
          <DemoNavbar {...props} />
          <Switch>
            {routes.map((prop, key) => {
              return (
                <Route
                  path={prop.path}
                  component={prop.component}
                  key={key}
                  exact
                />
              );
            })}
            <Redirect to="/dashboard" />
          </Switch>
          <Footer fluid />
        </div>
      </div>
    );


  }

}

export default Dashboard;
